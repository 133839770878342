<template>
  <!-- 提示弹窗 -->
  <van-dialog v-model:show="isPrompt" :showConfirmButton="false">
    <div class="dialog">
      <div class="title">
        <div>{{ $t("姓名") }}</div>
        <img @click="hide" src="@/assets/icons/ic_Close@2x.png" alt="" />
      </div>
      <div class="text">
        <van-field v-model="name" :placeholder="$t('姓名')" >
          <template #right-icon>
            <div v-if="name" @click="handleclear">
              <img class="close" src="@/assets/icons/close-popup.png" alt="">
            </div>
          </template>
        </van-field>
      </div>
      <div class="pay-btn">
        <div class="Confirm" @click="handleConfirm">
          {{ confirmText ? confirmText : $t("確認") }}
        </div>
        <!-- <div class="Cancel" @click="hide">
          {{ cancelText ? cancelText : $t("取消") }}
        </div> -->
      </div>
    </div>
  </van-dialog>

</template>
<script>
import mixins from "@/utils/mixins.js"
export default {
  name: "upDataName",
  mixins: [mixins],
  props: {
    content: {
      type: String,
      default: ""
    },
    confirmText: {
      type: String,
      default: ""
    },
    cancelText: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      isPrompt: false,
      name:'',
    }
  },
  watch: {
    content: {
      handler (val) {
        this.name = val
      },
      immediate: true
    }
  },
  methods: {
    open (data) {
      this.isPrompt = true
    },
    hide () {
      this.isPrompt = false
    },
    handleclear(){
      this.name= ''
    },
    handleConfirm () {
      const name = this.name.trim()
      if (!name) {
        this.$toast(this.$t('請輸入姓名'))
        return
      }
      if(name===this.content){
        this.hide()
        return
      }
      this.$emit('success',name)
      this.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  padding: 24px;

  .text {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #1a1a1a;
    text-align: center;

    span {
      color: #3491fa;
    }
  }
  :deep(.van-field){
    border-bottom:1px solid #F8F8F8;
  }
  .close{
    width:20px;
    height:20px;
  }
  .pay-btn {

    .Cancel,
    .Confirm {
      flex: 1;
      text-align: center;
      margin-top: 10px;
    }

    .Cancel {
      background: #eeeeee;
      border-radius: 12px 12px 12px 12px;
      padding: 8px;
    }

    .Confirm {
      font-size: 16px;
      color: #ffffff;
      background-color: #90d12e;
      border-radius: 12px 12px 12px 12px;
      padding: 8px;
    }
  }

  .title {
    text-align: center;
    margin-bottom: 12px;
    font-weight: 500;

    div {
      text-align: center;
    }

    img {
      width: 24px;
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
}
</style>