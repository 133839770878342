<template>
  <!-- 提示弹窗 -->
  <van-popup v-model:show="isPrompt" position="bottom" :style="{
    height: '80vh',
    borderRadius: '16px 16px 0 0',
    overflow: 'hidden'
  }" @closed="handleClose">
    <div class="dialog">
      <div class="title">
        <div>{{ $t("診所地址") }}</div>
        <img @click="hide" src="@/assets/icons/ic_Close@2x.png" alt="" />
      </div>
      <div class="main">
        <div class="AddressList" @click="openAddressDialog">
          <div :style="selectTitle ? 'color:#1A1A1A;' : ''">
            {{ selectTitle ? selectTitle : $t("所在地區") }}
          </div>
          <img src="@/assets/icons/arrow-rightactive.png" alt="" />
        </div>
        <div class="error" v-if="verifyobj.province" style="margin-top: 6px;">
          {{ $t("請選擇") }}{{ $t("所在地區") }}
        </div>
        <van-field style="margin-bottom: 12px;margin-top: 12px;" v-model="params.street"
          :placeholder="$t('大廈/屋苑&街道')" />
        <div class="error" v-if="verifyobj.street" style="margin-top: 6px;">
          {{ $t("請輸入") }}{{ $t("大廈/屋苑&街道") }}
        </div>
        <van-field v-model="params.address" :placeholder="$t('單位&樓層')" />
        <div class="error" v-if="verifyobj.address" style="margin-top: 6px;">
          {{ $t("請輸入") }}{{ $t("單位&樓層") }}
        </div>
      </div>
      <div class="pay-btn">
        <div class="Confirm" @click="handleConfirm">
          {{ confirmText ? confirmText : $t("保存") }}
        </div>
      </div>
    </div>
  </van-popup>
  <addressCascader :isShow="isShow" @handleClose="handleClose" @handleAreaCode="handleAreaCode"></addressCascader>
</template>
<script>
import { updateUserAddress } from "@/api/user"
import mixins from "@/utils/mixins.js"
export default {
  name: "upDataAddress",
  mixins: [mixins],
  props: {
    content: {
      type: String,
      default: ""
    },
    confirmText: {
      type: String,
      default: ""
    },
    cancelText: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      isPrompt: false,
      selectTitle: "",
      verifyobj: {
        province: "",
        address: "",
        street: "",
      },
      params: {
        address: "",
        province: "",
        city: "",
        district: "",
        street: "",
      },
      isShow:false
    }
  },
  watch: {
    content: {
      handler (val) {
        this.selectTitle = `${val?.province?.split("●")[1] || ''}${val?.city?.split("●")[1]
        ? "/" + val?.city?.split("●")[1]
        : ""
        }${val?.district?.split("●")[1]
          ? "/" + val?.district?.split("●")[1]
          : ""
        }`
        this.params.address = val?.address
        this.params.city = val?.city
        this.params.district = val?.district
        this.params.street = val?.street
        this.params.province= val?.province
      },
      immediate: true
    }
  },
  methods: {
    handleAreaCode (data) {
      const areaList = data.filter(item => item.value !== "1")
      areaList.forEach((item, index) => {
        if (index === 0) {
          this.params.province = item.value + "●" + item.label
        } else if (index === 1) {
          this.params.city = item.value + "●" + item.label
        } else if (index === 2) {
          this.params.district = item.value + "●" + item.label
        }
      })
      const res = areaList.map(items => items.label).join("/")
      this.selectTitle = res
    },
    openAddressDialog () {
      this.isShow = true
    },
    handleClose (data) {
      this.isShow = false
    },
    open (data) {
      this.isPrompt = true
    },
    hide () {
      this.isPrompt = false
    },
    // 验证
    handleExamine () {
      this.verifyList = []

      const keys = Object.keys(this.verifyobj)

      keys.forEach(item => {
        this.validatoFormFormcheck(item)
      })
      for (const key in this.verifyobj) {
        this.verifyobj[key] = ""
      }
      this.verifyList.forEach(item => {
        this.verifyobj[item] = true
      })
      if (this.verifyList.length > 0) {
        return false
      } else {
        return true
      }
    },
    validatoFormFormcheck (label) {
      if (!this.params[label]) {
        this.verifyList.push(label)
      } else {
        const index = this.verifyList.findIndex(item => item === label)
        if (index != -1) {
          this.verifyList.splice(index, 1)
        }
      }
    },
    async handleConfirm () {
      const isSubmit = await this.handleExamine()
      if(isSubmit){
        this.$emit('success',this.params)
        this.hide()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  padding: 24px;
  height:100%;
  width:100%;
  box-sizing: border-box;
  position: relative;
 .main{
  .AddressList {
      position: relative;
      z-index: 2;
      width: 100%;
      border-radius: 12px 12px 12px 12px;
      border: 1px solid #ebebeb;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #999999;
      font-size: 16px;
      background-color: #fff;
      img {
        width: 20px;
      }
    }
    .error {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #f82207;
      line-height: 18px;
      padding-left: 12px;
      margin-top: 1px;
    }

  }
 .van-field {
    background-color: #fff;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #ebebeb;
    margin-bottom: 12px;
    padding: 16px;

    .van-field__control {
      font-size: 16px;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;

      &::placeholder {
        color: #999999;
      }
    }
  }
  .close{
    width:20px;
    height:20px;
  }
  .pay-btn {
    position: absolute;
    min-width:88%;
    max-width:95%;
    bottom: 20px;
    left:24px;

    .Cancel,
    .Confirm {
      flex: 1;
      text-align: center;
      margin-top: 10px;
    }

    .Cancel {
      background: #eeeeee;
      border-radius: 12px 12px 12px 12px;
      padding: 8px;
    }

    .Confirm {
      font-size: 16px;
      color: #ffffff;
      background-color: #90d12e;
      border-radius: 12px 12px 12px 12px;
      padding: 8px;
    }
  }

  .title {
    text-align: center;
    margin-bottom: 12px;
    font-weight: 500;

    div {
      text-align: center;
    }

    img {
      width: 24px;
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
}
</style>