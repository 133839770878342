<template>
  <div class="infoPage-box">
    <nav-bar :title="$t('賬戶資料')" :isFixed="true" :bgc="'background: #ffffff'" />
    <div class='box-main'>
      <div class='box-main-top'>
        <div class="avatar-box">
          <van-uploader :max-count="1" :before-read="beforeRead" :after-read="afterRead" v-model="fileList"
            preview-size="100%">
            <template #default>
              <div class="avatar">
                <img class="avatar-img" v-if="userInfo.avatar && userInfo.avatar !== '/avatar/default.png'"
                  :src="BASE_IMG + userInfo.avatar" alt="" />
                <div v-else class="avatar-text">
                  {{ userInfo.name?.substr(0,1).toUpperCase() }}
                </div>
                <img class='avatar-edit' src="@/assets/icons/avatar-edit.png" alt="">
              </div>
            </template>
          </van-uploader>
        </div>

        <div class="avatar-name">{{ userInfo.clinicName }}</div>
      </div>
      <div class="box-content">
        <div class="box-item" @click="openUpdataName()">
          <div class="left">
            <div class="title">{{ $t('姓名') }}</div>
          </div>
          <div class="right">
            <div class="title">{{ userInfo.clinicName }}</div>
            <img src="@/assets/icons/arrow-right.png" alt="" />
          </div>
        </div>
        <div class="box-item" @click="gotophoneVerify">
          <div class="left">
            <div class="title">{{ $t('手機號') }}</div>
          </div>
          <div class="right">
            <div class="title">{{ getphone }}</div>
            <img src="@/assets/icons/arrow-right.png" alt="" />
          </div>
        </div>
        <div class="box-item" @click="gotoemailVerify">
          <div class="left">
            <div class="title">{{ $t('郵箱地址') }}</div>
          </div>
          <div class="right">
            <div class="title">{{ getemail }}</div>
            <img src="@/assets/icons/arrow-right.png" alt="" />
          </div>
        </div>
      </div>
      <div class="box-content">
        <div class="status">
          <div class="status-text">
            <div
              :class="['drop',userInfo.authStatus === '0' ? 'orange-bg' : userInfo.authStatus === '1' ? 'green-bg' : 'red-bg']">
            </div>
            <div
              :class="['tigs',userInfo.authStatus === '0' ? 'orange-color' : userInfo.authStatus === '1' ? 'green-color' : 'red-color']">
              {{ getStatus }}</div>
          </div>
          <div class="status-updata" v-if="userInfo.authStatus === '2'">{{ $t('修改') }}</div>
        </div>
        <div class="box-item" @click="gotoClinicVerify">
          <div class="left">
            <div class="title">{{ $t('診所名稱') }}</div>
          </div>
          <div class="right">
            <div class="name" v-if="userInfo.authStatus !== '4'">{{ userInfo.companyName }}</div>
          </div>
        </div>
        <div class="box-item">
          <div class="left">
            <div class="title">營業執照</div>
          </div>
          <div class="right">
            <div class="check" @click="gotoClinicVerify">{{ userInfo.authStatus === '4' ? $t('去認證') : $t('查看') }}</div>
          </div>
        </div>
      </div>
      <div class="box-content2" @click="openUpdataAddress">
        <div class="title">{{ $t('診所地址') }}</div>
        <div class="address-box">
          <div>
            <div class="address-text">
              {{ userInfo.address }}
            </div>
            <div class="address-text">
              {{ userInfo.street }}
            </div>
            <div class="address-text" v-if="userInfo.province">
              {{ spliceAddress(userInfo.province) }}{{ spliceAddress(userInfo.city) }}{{
                spliceAddress(userInfo.district) }}
            </div>
          </div>
          <img src="@/assets/icons/arrow-right.png" alt="" />
        </div>
      </div>
    </div>
    <upDataName ref="upDataNameRef" :content="userInfo.clinicName" @success="handlesuccess" />
    <upDataAddress ref="upDataAddressRef" :content="userInfo" @success="handleAddress" />
  </div>
</template>
<script>
import mixins from "@/utils/mixins.js"
import { mapState } from "vuex"
import { updateUser, updateUserAddress } from "@/api/user"
import upDataName from "./components/upData-name.vue"
import upDataAddress from "./components/upData-address.vue"
export default {
  name: "InfoPage",
  mixins: [mixins],
  data () {
    return {
      fileList: [],
    }
  },
  components: {
    upDataName,
    upDataAddress
  },
  computed: {
    ...mapState("user", ["userInfo", "phoneCode"]),
    address () {
      return this.$store.state.user.address
    },
    getphone () {
      if (this.userInfo.phone) {
        const str = `${this.userInfo.areaCode}-${this.userInfo.phone}`
        const before = str.slice(0, 5)
        const after = str.slice(str.length - 2, str.length)
        const stars = '*'.repeat(6)
        return before + stars + after
      } else {
        return ''
      }

    },
    getemail () {
      if (this.userInfo.email) {
        const str = `${this.userInfo.email}`
        const before = str.slice(0, 3)
        const after = str.slice(str.length - 8, str.length)
        const stars = '*'.repeat(4)
        return before + stars + after
      } else {
        return ''
      }

    },
    getStatus () {
      switch (this.userInfo.authStatus) {
        case '0':
          return '審核中'
        case '1':
          return '已通過'
        case '2':
          return '未通過'
        case '4':
          return '暫未認證'
        default:
          return ''
      }
    }
  },
  async created () {
    this.$store.dispatch("user/getUserInfo")
  },
  methods: {
    gotoClinicVerify () {
      if (this.userInfo.authStatus === "4") {
        this.$router.push("/verify?qwe=true")
        return
      }
      this.$router.replace("/clinicVerify")
    },
    gotoemailVerify () {
      this.$router.replace("/emailVerify")
    },
    gotophoneVerify () {
      this.$router.replace("/phoneVerify")
    },
    async handleAddress (data) {
      const res = await updateUserAddress(data)
      if (res.resultID == 1200) {
        this.$store.dispatch("user/getUserInfo")
        this.$toast(this.$t('操作成功'))
      } else {
        this.$toast(res.message)
      }
    },
    async handlesuccess (name) {
      const data = new FormData()
      data.append('userName', name)
      const res = await updateUser(data)
      if (res.resultID == 1200) {
        this.$store.dispatch("user/getUserInfo")
        this.$toast(this.$t('操作成功'))
      } else {
        this.$toast(res.message)
      }
    },
    openUpdataName () {
      this.$refs.upDataNameRef.open()
    },
    openUpdataAddress () {
      this.$refs.upDataAddressRef.open()
    },
    spliceAddress (str) {
      const regex = new RegExp(`(?:${'●'})(.*)`)
      const match = (str || '').match(regex)
      return match && match[1] ? match[1] + ',' : ''
    },
    async beforeRead (file) {
      const maxSize = 2 * 1024 * 1024
      if (file.size > maxSize) {
        this.$toast("文件體積過大,請小于2mb")
        return false
      }
      const data = new FormData()
      data.append('file', file)
      const res = await updateUser(data)
      if (res.resultID == 1200) {
        this.$store.dispatch("user/getUserInfo")
        this.$toast(this.$t('操作成功'))
      } else {
        this.$toast(res.message)
      }

    },
    afterRead (file) {
      this.$nextTick(() => {
        this.fileList = []
      })
    },

  }
}
</script>
<style lang="scss" scoped>
.infoPage-box {
  padding: 44px 10px 0;

  // box-sizing: border-box;
  // height:100vh;
  .box-main {
    padding-top: 40px;

    .box-main-top {
      padding-bottom: 28px;

      .avatar-box {
        width: 88px;
        height: 88px;
        margin: 0 auto;
        background: #90d12e;
        border-radius: 50%;
      }

      .avatar {
        width: 88px;
        height: 88px;
        position: relative;


        .avatar-text {
          width: 100%;
          height: 100%;
          text-align: center;
          line-height: 88px;
          font-size: 32px;
          font-weight: 700;
          color: #fff;
          background: #90d12e;
          border-radius: 50%;
        }

        .avatar-img {
          width: 88px;
          height: 88px;
          border-radius: 44px 44px 44px 44px;
        }

        .avatar-edit {
          width: 24px;
          height: 24px;
          position: absolute;
          right: 0px;
          bottom: 0px;
        }
      }

      .avatar-name {
        font-weight: 500;
        font-size: 18px;
        color: #1A1A1A;
        line-height: 28px;
        text-align: center;
        margin-top: 16px;
      }
    }

    .box-content {
      margin-top: 12px;
      background: #ffffff;
      border-radius: 10px;
      padding: 0 16px;

      .status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 12px;

        .status-text {
          display: flex;
          align-items: center;

          .drop {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            margin-right: 4px;
          }

          .red-bg {
            background: #F82207;
          }

          .orange-bg {
            background: #FF9900;
          }

          .green-bg {
            background: #00D06D;
          }

          .tigs {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
          }

          .red-color {
            color: #F82207;
          }

          .orange-color {
            color: #FF9900;
          }

          .green-color {
            color: #00D06D;
          }
        }

        .status-updata {
          text-align: center;
          width: 52px;
          height: 28px;
          border-radius: 30px 30px 30px 30px;
          border: 1px solid #90D12E;
          font-weight: 400;
          font-size: 14px;
          color: #90D12E;
          line-height: 28px;
        }
      }

      .box-item {
        padding: 20px 0px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #F8F8F8;

        .left {
          flex: 1;

          .title {
            font-weight: 500;
            font-size: 17px;
            color: #1A1A1A;
            line-height: 24px;
          }
        }

        .right {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .title {
            font-weight: 400;
            font-size: 16px;
            color: #666666;
            line-height: 24px;
          }

          img {
            width: 16px;
            height: 16px;
            margin-left: 12px;
          }

          .name {
            font-weight: 400;
            font-size: 16px;
            color: #999999;
            line-height: 24px;
            text-align: right;
            margin-right: 12px;
          }

          .check {
            font-weight: 400;
            font-size: 14px;
            color: #90D12E;
            line-height: 22px;
            margin-right: 12px;
          }
        }

        &:last-child {
          border: 0
        }
      }
    }

    .box-content2 {
      padding: 20px 16px;
      margin-top: 12px;
      background: #ffffff;
      border-radius: 10px;

      .title {
        font-weight: 500;
        font-size: 17px;
        color: #1A1A1A;
        line-height: 24px;
      }

      .address-box {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .address-text {
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 22px;
          text-align: left;
        }

        img {
          width: 16px;
          height: 16px;
          margin-left: 12px;
        }
      }

    }
  }
}
</style>
